import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75f87f50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-light h-full" }
const _hoisted_2 = { class: "container py-8" }
const _hoisted_3 = { class: "text-primary" }
const _hoisted_4 = { class: "flex flex-row justify-center" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row justify-center"
}
const _hoisted_6 = { class: "py-8" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "flex flex-row justify-center flex-wrap py-8" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
        [_directive_t, 'poop.textA']
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("button", {
          class: _normalizeClass([{ active: _ctx.type == 'colors' }, "bg-white border-secondary text-primary px-8 py-2 rounded-full m-4 border-2 focus:outline-none shadow-around"]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectType('colors')))
        }, null, 2), [
          [_directive_t, 'poop.colors']
        ]),
        _withDirectives(_createElementVNode("button", {
          class: _normalizeClass([{ active: _ctx.type == 'shapes' }, "bg-white border-secondary text-primary px-8 py-2 rounded-full m-4 border-2 focus:outline-none shadow-around"]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectType('shapes')))
        }, null, 2), [
          [_directive_t, 'poop.shapes']
        ])
      ]),
      (_ctx.type)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _withDirectives(_createElementVNode("button", {
              class: _normalizeClass([{ active: _ctx.attribute == 'good' }, "bg-white border-secondary text-primary px-8 py-2 rounded-full m-4 border-2 focus:outline-none shadow-around"]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectAttribute('good')))
            }, null, 2), [
              [_directive_t, 'poop.good']
            ]),
            _withDirectives(_createElementVNode("button", {
              class: _normalizeClass([{ active: _ctx.attribute == 'bad' }, "bg-white border-secondary text-primary px-8 py-2 rounded-full m-4 border-2 focus:outline-none shadow-around"]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectAttribute('bad')))
            }, null, 2), [
              [_directive_t, 'poop.bad']
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (it, k) => {
          return (_openBlock(), _createElementBlock("div", { key: k }, [
            (_ctx.type == undefined || _ctx.type == k)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['good', 'bad'], (m) => {
                    return _createElementVNode("div", {
                      key: `${k}-${m}`
                    }, [
                      (_ctx.attribute == undefined || _ctx.attribute == m)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", {
                              class: "text-primary font-display font-bold text-5xl text-center",
                              innerHTML: it[m]['title']
                            }, null, 8, _hoisted_9),
                            _createElementVNode("div", _hoisted_10, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(it[m]['items'], (item, i) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "text-center flex flex-col justify-end p-4 w-48",
                                  key: 'item-' + i
                                }, [
                                  _createElementVNode("img", {
                                    src: 
                        `/img/poop/results/${k}/${m}/${item['image']}.${it.type}`
                      ,
                                    class: "mb-4"
                                  }, null, 8, _hoisted_11),
                                  _createElementVNode("div", {
                                    class: "text-primary text-lg text-center whitespace-nowrap",
                                    innerHTML: item['description']
                                  }, null, 8, _hoisted_12)
                                ]))
                              }), 128))
                            ]),
                            _createElementVNode("div", {
                              class: "text-primary text-lg text-center mb-8",
                              innerHTML: _ctx.t(it[m]['description'])
                            }, null, 8, _hoisted_13)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  }), 64))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}
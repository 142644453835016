
import { defineComponent, ref, computed } from "vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "Poop",
  setup() {
    const t = i18n.global.t;

    const data = computed(() => ({
      colors: {
        type: "png",
        good: {
          title: t("poop.goodColorsTitle"),
          description: t("poop.goodColorsText"),
          items: [
            { image: "greenish-coffee", description: t("poop.greenishCoffee") },
            { image: "coffee", description: t("poop.coffee") },
            { image: "coffee-yellow", description: t("poop.coffeeYellow") },
            { image: "dark-green", description: t("poop.darkGreen") }
          ]
        },
        bad: {
          title: t("poop.badColorsTitle"),
          description: t("poop.badColorsText"),
          items: [
            { image: "red", description: t("poop.red") },
            { image: "black", description: t("poop.black") },
            { image: "white", description: t("poop.white") },
            { image: "with-red-bars", description: t("poop.withRedBars") }
          ]
        }
      },
      shapes: {
        type: "svg",
        good: {
          title: t("poop.goodShapesTitle"),
          description: t("poop.goodShapesText"),
          items: [
            { image: "spongy", description: t("poop.spongy") },
            { image: "sausage", description: t("poop.sausage") }
          ]
        },
        bad: {
          title: t("poop.badShapesTitle"),
          description: t("poop.badShapesText"),
          items: [
            {
              image: t("poop.parsleyImageName"),
              description: t("poop.parsley")
            },
            { image: "oats", description: t("poop.oats") },
            { image: "grape", description: t("poop.grape") },
            { image: "chicken", description: t("poop.chicken") },
            { image: "rabbit", description: t("poop.rabbit") }
          ]
        }
      }
    }));

    const type = ref();
    const attribute = ref();

    const selectType = (val: string) => {
      type.value = val;
    };

    const selectAttribute = (val: string) => {
      attribute.value = val;
    };

    return {
      type,
      attribute,
      selectType,
      selectAttribute,
      data,
      t
    };
  }
});
